.App {
  text-align: center;
  background-color: #ba583a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.App-logo {
  height: 14vmin;

  pointer-events: none;
  min-height: 18vmin;
  margin: 120px 0 60px 0;

  @media (min-width: 1000px) {
    margin: 150px 0 50px 0;
    min-height: auto;
  }
}

a,
a:hover,
a:focus,
a:active {
  color: inherit;
}

.Main-links {
  color: #000000 !important;
  font-family: 'CaslonC540BT-Italic', serif;
  /* font-family: 'adobe-caslon-pro', serif; */
  font-weight: 400;
  font-style: italic;
  font-size: 32px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0px 0 50px 0;
  @media (min-width: 1000px) {
    font-size: 36px;
    margin: 0px 0 60px 0;
  }
}

.App-link {
  margin-bottom: 30px;
  &:hover {
    letter-spacing: 1px;
  }
}

.Footer-links {
  color: #000000 !important;
  font-size: 18px;
  font-family: 'Inter Tight', sans-serif;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 35px 0;
  text-decoration: underline;

  @media (min-width: 1000px) {
    margin: 0 0 50px 0;
  }
}

.App-bottom-link {
  margin-bottom: 20px;
}

a[href^='tel'] {
  text-decoration: inherit;
  color: inherit;
}
